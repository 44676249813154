import * as React from "react";

import Layout from "../../components/Layout";
import NewsRoll from "../../components/NewsRoll";
import { Helmet } from "react-helmet"

export default class NewsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section mb-6">
          <div className="container">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Compensation Benchmarking & Planning Resources - Kamsa</title>
              <meta properties="og:title" content="Compensation Benchmarking & Planning Resources - Kamsa" />
              <meta name="description" content="Get the latest insights on effective compensation benchmarking, review and planning. Learn salary budgeting and job leveling from our compensation experts." />
            </Helmet>
            <div className="block has-text-centered">
              <h1 className="title is-h1">News</h1>
              <h4 className="is-h4 is-offset-1">Latest news and announcements</h4>
            </div>

            <NewsRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
