import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class NewsRollTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent mb-4" key={post.id}>
              <Link
                className={`article-list-item is-flex is-flex-direction-column tile is-child box ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
                to={post.fields.slug}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="block post-meta">
                    <p className="title is-size-4 mb-0">
                      {post.frontmatter.title}
                    </p>
                    <p className="is-h4 mt-1">{post.frontmatter.date}</p>
                  </div>
                </header>
                <p className="is-flex-grow-1">
                  {post.excerpt}
                </p>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

NewsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function NewsRoll() {
  return (
    <StaticQuery
      query={graphql`
        query NewsRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "news-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 120
                        quality: 100
                        layout: CONSTRAINED
                      )

                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <NewsRollTemplate data={data} count={count} />}
    />
  );
}
